<template>
  <PageContainer :title="title" :back="actions.back">
    <template v-slot:content>
      <div class="segment" v-if="loading">
        <div class="segment-content">
          <SkeletonDefault />
        </div>
      </div>

      <div v-if="!loading" class="segment">
        <div class="segment-content">
          <div class="heading-small padding-bottom" v-text="titleResult" />
          <SpecialistCard
            v-bind:key="specialist.id"
            v-for="specialist in specialistsArray"
            v-bind:specialist="specialist"
          />
        </div>
      </div>
    </template>
  </PageContainer>
</template>

<script>
import { PageContainer } from '@seliaco/red-panda'
import SkeletonDefault from '@/components/skeletons/SkeletonDefault'
import SpecialistCard from '@/components/cards/specialist-card/SpecialistCardWrapper.vue'

export default {
  name: 'SpecialistsList',
  components: {
    SpecialistCard,
    PageContainer,
    SkeletonDefault
  },
  props: {
    title: String,
    specialists: Array,
    source: {
      id: Number,
      store: String,
      action: String,
      getter: String
    }
  },
  data () {
    const actions = {
      back: {
        callback: () => {
          this.back()
        }
      }
    }

    return {
      actions
    }
  },
  mounted () {
    if (!this.specialists && !this.source) {
      this.back()
    }

    if (this.source) {
      this.dispatchStore()
    }
  },
  methods: {
    dispatchStore () {
      const moduleStore = `${this.source.store}/${this.source.action}`
      this.$store.dispatch(moduleStore, { id: this.source.id })
    },
    back () {
      if (this.$route.query.back) {
        this.$router.replace({
          path: this.$route.query.back
        })
      } else {
        this.$router.go(-1)
      }
    }
  },
  computed: {
    titleResult () {
      return `${this.specialistsArray.length} ${this.$translations.general.specialists}`
    },
    loading () {
      if (this.specialists) {
        return false
      }

      if (this.source) {
        const moduleStore = `${this.source.store}/getLoading`
        return this.$store.getters[moduleStore]
      }

      return true
    },
    specialistsArray: {
      get () {
        if (this.source) {
          const moduleStore = `${this.source.store}/${this.source.getter}`
          return this.$store.getters[moduleStore]
        }

        return this.specialists
      },
      set (val) {
        if (this.source) {
          const moduleStore = `${this.source.store}/${this.source.getter}`
          return this.$store.getters[moduleStore]
        }

        return val
      }
    }
  }
}
</script>
